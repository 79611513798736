<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  norm: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UN") : $t("MODIFICATION_DU") }} {{$t("LIBRARY")}}  </h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
      <!--   <p class="pt-4">{{ $t("name") }}<b style="color: #ff6141" >*</b> </p>
        <vs-input
          disabled
          data-vv-validate-on="blur"
          name="name"
          v-model="name_file1_3"
          class="w-full" />         
     
        <div class="p-6">  -->
   
        <!--image/*-->      
        <span><b style="color:orange">{{ name_file1_3}}</b></span>
        <input  name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".pdf">
        <span class="text-danger text-sm" v-show="errors.has('name_file1_3')">{{ errors.first("name_file1_3") }}</span>
        <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">{{$t("choisir")}}</vs-button>

  
 <!--  </div> -->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button :disabled="!validate_filen_name" class="mr-6" @click="category_validate">{{$t("Soumettre")}}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      name: '',
      dataImg1_3:'',
      name_file1_3:'',
      id:'',
      updatechek:false,
      input:'',
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
        this.updatechek = false
      }
    }
  },

  computed: {
    validate_filen_name () {
      if ((this.name_file1_3 !== '')) return true
    },
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        // this.$validator.reset()
        // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
  
    updateCurrImg1_3 (input) {
      if (input.target.files && input.target.files.length >0) {
        this.dataImg1_3 = (input.target.files[0])
        this.name_file1_3 = (input.target.files[0].name)       
        
      }
    },

    initValues () {
      this.id = ''
      this.dataImg1_3 = ''
      this.name_file1_3 = ''
      this.updatechek = false
    },

    category_validate () {
      if (this.dataImg1_3) {
        this.submitData()
      }
    },

    async submitData () {
      const formdata = new FormData()
      if (this.dataImg1 !== null) {
        formdata.append('files', this.dataImg1_3,  this.name_file1_3)
      }
     
      this.$vs.loading()
   
      let url = 'risk-library/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('library_save')
      }
      if (this.updatechek === true) {
        if (this.input.id) {
          url += `${this.input.id}/`
          methods = 'put'
          message.success = this.$t('library_update')
        }
      }
   
      this.$http[methods](url, formdata)
        .then((response) => {
          window.libraries.getAlllibraries()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            if (item === 'file_path') {
              this.$vs.notify({
                time: 6000,
                title: 'ATTENTION',
                text:this.$t('library'),
                color: 'warning',
                position: 'top-left'
              })
            }
           
          }
          this.$vs.notify({
            time: 8000,
            title: 'ENREGISTREMENT',
            text: error.response.data.error,
            color: 'danger',
            position: 'top-right'
          })
          this.$vs.loading.close()
          //window.getPrendTaCom.error(message.error)
        })
    }

  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-²-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
